:root {
  --surface-color: #fff;
  --curve: 40;
}

* {
  box-sizing: border-box;
}
.title-card {
  display: flex;
  max-width: 1285px;
  margin: 0 auto;
  padding-top: 1rem;
  justify-content: flex-start;
}
.cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0.2fr));
  gap: 2rem;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.card {
  position: relative;
  display: block;
  height: 320px;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  object-fit: cover;
}

.card__image {
  border-radius: 0 calc(var(--curve) * 1px) calc(var(--curve) * 1px)
    calc(var(--curve) * 1px);
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.5s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1.2em;
  border-radius: calc(var(--curve) * 1px) calc(var(--curve) * 1px) 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: 1s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card:hover .card__header {
  transform: translateY(0);
  transition: 0.5s ease-in-out;
  padding: 1.2em 0.6rem;
  padding-bottom: 0.5rem;
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  font-size: 0.8rem;
  color: #444446;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: 0.8em;
  color: #747584;
}

.card__status {
  font-size: 0.8em;
  color: #747584;
}

.card__description {
  margin: 0 2.5em 1em 2.5em;
  font-size: 0.7em;
  color: #444446;
}
.card__description p {
  margin-bottom: 0.4rem;
}
.card:hover {
  z-index: 1;
  transition: 0.5s;
  /* top: 50%;
    left: 50%;
    translate(-50%,-50%)
    */
  transform: scale(1.3);
}
.card_plus {
  width: 70%;
  min-width: 300px;
  font-size: 1em;
  border-radius: 15px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  height: 70%;
  border: rgb(255, 255, 255) solid 20px;
}
.plus_txt {
  overflow-y: auto;
  height: 80%;
  padding-right: 20px;
}
.box-detalis {
  display: flex;
  height: 20px;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-bottom: 3.4em;
  flex-direction: row;
}
.icon_close_plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.plus_icon_icon {
  margin-right: 0.2em;
}
.Button_details {
  max-width: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 0;
}
.Button_details p {
  margin-bottom: 0;
}
.title-plus-img {
  margin: 0px 1.5rem 0px;
}
.Title_card_plus {
  display: flex;
}
.bi-plus-circle {
  width: 100px;
}
